import Header from "./Header.js";
import Presentation from "./Presentation";
import Projects from "./Projects";
import Skills from "./Skills";
import About from "./About";
import Contact from "./Contact";
import { Container } from "react-bootstrap";
import "./App.css";

function App() {
  return (
    <div className="app" id="home">
      <Container fluid className="px-0">
        <Header />
        <Presentation />
        <Projects />
        <Skills />
        <About />
        <Contact />
      </Container>
    </div>
  );
}

export default App;
