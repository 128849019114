import React from "react";
import ProjectItem from "./ProjectItem";

import "./Projects.css";

function Projects() {
  return (
    <div className="projects" id="projects">
      <div id="headerContainer" className="text-center">
        <h2># P r o j e c t s</h2>
      </div>
      <ProjectItem
        projectTitle="Volunteer Land"
        projectSubtitle="A volunteer app concept"
        projectWebLink="https://volunteer-land.web.app/"
        projectGitHubLink="https://github.com/bruno-cap/volunteer-land-client"
        tabletImages={[
          "/Images/volunteer-land/tablet1.jpg",
          "/Images/volunteer-land/tablet2.jpg",
          "/Images/volunteer-land/tablet3.jpg",
        ]}
        notebookImages={[
          "/Images/volunteer-land/notebook1.jpg",
          "/Images/volunteer-land/notebook2.jpg",
          "/Images/volunteer-land/notebook3.jpg",
        ]}
        phoneImages={[
          "/Images/volunteer-land/phone1.jpg",
          "/Images/volunteer-land/phone2.jpg",
          "/Images/volunteer-land/phone3.jpg",
        ]}
        projectHighlights={[
          "Add opportunities and custom questions for candidates to answer + Access applicant list and individual applications",
          "Candidates can add multiple resumes and choose which to attach to each application",
          "React + Hooks + Context API + Bootstrap, Django + Rest Framework API + SimpleJWT (JSON Web Tokens), and PostgreSQL",
        ]}
      />

      <ProjectItem
        projectTitle="The Social Hub"
        projectSubtitle="A social media app concept"
        projectWebLink="https://the-social-hub-c8775.web.app/"
        projectGitHubLink="https://github.com/bruno-cap/the-social-hub"
        tabletImages={[
          "/Images/the-social-hub/tablet1.jpg",
          "/Images/the-social-hub/tablet2.jpg",
          "/Images/the-social-hub/tablet3.jpg",
        ]}
        notebookImages={[
          "/Images/the-social-hub/notebook1.jpg",
          "/Images/the-social-hub/notebook2.jpg",
          "/Images/the-social-hub/notebook3.jpg",
        ]}
        phoneImages={[
          "/Images/the-social-hub/phone1.jpg",
          "/Images/the-social-hub/phone2.jpg",
          "/Images/the-social-hub/phone3.jpg",
        ]}
        projectHighlights={[
          "Likes, comments, user murals, messenger, friend requests, notifications, and more!",
          "Real-time updates with database listeners",
          "React + Hooks + Context API + Bootstrap, and Google Firebase + Cloud Firestore",
        ]}
      />

      <ProjectItem
        projectTitle="My Gift Shop"
        projectSubtitle="An e-commerce app concept"
        projectWebLink="https://my-gift-shop.herokuapp.com/"
        projectGitHubLink="https://github.com/bruno-cap/my-gift-shop"
        tabletImages={[
          "/Images/my-gift-shop/tablet1.jpg",
          "/Images/my-gift-shop/tablet2.jpg",
          "/Images/my-gift-shop/tablet3.jpg",
        ]}
        notebookImages={[
          "/Images/my-gift-shop/notebook1.jpg",
          "/Images/my-gift-shop/notebook2.jpg",
          "/Images/my-gift-shop/notebook3.jpg",
        ]}
        phoneImages={[
          "/Images/my-gift-shop/phone1.jpg",
          "/Images/my-gift-shop/phone2.jpg",
          "/Images/my-gift-shop/phone3.jpg",
        ]}
        projectHighlights={[
          "Shopping Cart, confirmation e-mails, e-mail subscription list, and more!",
          "Clerk modules to add, update, and delete products",
          "Backend validation with regular expressions",
          "HTML, CSS + Bootstrap, Javascript, Handlebars.js, NodeJS + Express, and MongoDB via Mongoose",
        ]}
      />
    </div>
  );
}

export default Projects;
