import React from "react";
import emailjs from "emailjs-com";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import "./Contact.css";

function Contact() {
  const sendEmail = (e) => {
    e.preventDefault();

    console.log(e);

    emailjs
      .sendForm(
        "service_2o0egvf",
        "template_puytegt",
        e.target,
        "user_lLpKt9dHVXpAguaz43mO5"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div className="contact" id="contact">
      <h2># C o n t a c t</h2>

      <Form onSubmit={sendEmail}>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control required type="text" name="name" />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>E-mail</Form.Label>
          <Form.Control required type="email" name="email" />
        </Form.Group>

        <Form.Group controlId="formMessage">
          <Form.Label>Message</Form.Label>
          <Form.Control required as="textarea" rows={5} name="message" />
        </Form.Group>

        <Button
          className="d-block mx-auto"
          id="sendButton"
          variant="primary"
          type="submit"
        >
          Send
        </Button>
      </Form>

      <div id="contactOptions" className="d-flex justify-content-center">
        <Link
          to={{ pathname: "mailto:brunocap@outlook.com" }}
          className="iconLinks"
        >
          <MailOutlineIcon className="footerIcons" fontSize="large" />
        </Link>
        <Link
          to={{ pathname: "https://www.linkedin.com/in/brunocabralp" }}
          target="_blank"
          className="iconLinks"
        >
          <LinkedInIcon className="footerIcons" fontSize="large" />
        </Link>
        <Link
          to={{ pathname: "https://github.com/bruno-cap" }}
          target="_blank"
          className="iconLinks my-auto"
        >
          <GitHubIcon
            className="footerIcons my-auto"
            style={{ height: "27px", width: "27px" }}
          />
        </Link>
      </div>
    </div>
  );
}

export default Contact;
