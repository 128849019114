import React from "react";
import { Link } from "react-router-dom";
import GitHubIcon from "@material-ui/icons/GitHub";
import LanguageIcon from "@material-ui/icons/Language";
import CodeIcon from "@material-ui/icons/Code";
import ProjectCarousels from "./ProjectCarousels";
import "./ProjectItem.css";

function ProjectItem(props) {
  return (
    <div className="projectItem text-center">
      <h3>{props.projectTitle}</h3>
      <h4>{props.projectSubtitle}</h4>

      <ProjectCarousels
        tabletImages={props.tabletImages}
        notebookImages={props.notebookImages}
        phoneImages={props.phoneImages}
      />

      <div className="projectInfoBox mx-auto">
        <Link
          alt="Live Link"
          to={{ pathname: props.projectWebLink }}
          target="_blank"
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          Live!
          <LanguageIcon className="webIcon" />
        </Link>
        <Link
          alt="GitHub Link"
          to={{ pathname: props.projectGitHubLink }}
          target="_blank"
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <GitHubIcon className="gitHubIcon" /> Code
        </Link>
        {props.projectHighlights.map((projectHighlight, index) => (
          <p key={projectHighlight[index]}>
            <CodeIcon fontSize="small" />
            &nbsp; {projectHighlight}
          </p>
        ))}
      </div>
    </div>
  );
}

export default ProjectItem;
