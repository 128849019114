import React, { useEffect } from "react";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import "./Presentation.css";

function Presentation() {
  useEffect(() => {
    document.getElementById("myInfo").classList.add("show");
    document.getElementById("name").classList.add("showName");
    document.getElementById("position").classList.add("showPosition");
    document.getElementById("location").classList.add("showLocation");
  }, []);

  return (
    <div className="presentation" id="myPresentation">
      <div id="myInfo">
        <h1 id="name">Bruno Cabral</h1>
        <h2 id="position">Jr. Full-Stack Developer</h2>
        <h3 id="location">
          <LocationOnIcon id="locationIcon" fontSize="large" />
          Toronto
        </h3>
      </div>
      <img src="Images/ProfilePic.jpg" alt="Profile Picure" />
    </div>
  );
}

export default Presentation;
