import React, { useRef } from "react";
import Flickity from "react-flickity-component";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import "./flickity.css";
import "./ProjectCarousels.css";

function ProjectCarousels(props) {
  let tabletCarouselRef = useRef("");
  let notebookCarouselRef = useRef("");
  let mobileCarouselRef = useRef("");

  const setPrevious = () => {
    tabletCarouselRef.previous();
    notebookCarouselRef.previous();
    mobileCarouselRef.previous();
  };

  const setNext = () => {
    tabletCarouselRef.next();
    notebookCarouselRef.next();
    mobileCarouselRef.next();
  };

  const flickityOptions = {
    pageDots: false,
    wrapAround: true,
    draggable: false,
    prevNextButtons: false,
  };

  return (
    <div className="projectCarousels">
      <div className="projectCarouselsImgContainer">
        <div className="deviceImages d-flex justify-content-center align-items-end mx-0 px-0">
          <KeyboardArrowLeftIcon
            fontSize="large"
            onClick={setPrevious}
            className="arrows"
          />
          <KeyboardArrowRightIcon
            fontSize="large"
            onClick={setNext}
            className="arrows"
          />
          <img src="/Images/tablet.png" alt="tablet" className="mb-3" />
          <img src="/Images/notebook.png" alt="notebook" className="mx-1" />
          <img src="/Images/phone.png" alt="phone" className="mb-3" />
        </div>

        <div className="notebookCarousel">
          <Flickity
            className={"carousel"}
            elementType={"div"}
            options={flickityOptions}
            disableImagesLoaded={false}
            reloadOnUpdate
            static
            flickityRef={(e) => (notebookCarouselRef = e)}
          >
            <img
              src={props.notebookImages[0]}
              alt=""
              className="notebookProjectImages"
            />
            <img
              src={props.notebookImages[1]}
              alt=""
              className="notebookProjectImages"
            />
            <img
              src={props.notebookImages[2]}
              alt=""
              className="notebookProjectImages"
            />
          </Flickity>
        </div>

        <div className="tabletCarousel">
          <Flickity
            className={"carousel"}
            elementType={"div"}
            options={flickityOptions}
            disableImagesLoaded={false}
            reloadOnUpdate
            static
            flickityRef={(e) => (tabletCarouselRef = e)}
          >
            <img
              src={props.tabletImages[0]}
              alt=""
              className="tabletProjectImages"
            />
            <img
              src={props.tabletImages[1]}
              alt=""
              className="tabletProjectImages"
            />
            <img
              src={props.tabletImages[2]}
              alt=""
              className="tabletProjectImages"
            />
          </Flickity>
        </div>

        <div className="phoneCarousel">
          <Flickity
            className={"carousel"}
            elementType={"div"}
            options={flickityOptions}
            disableImagesLoaded={false}
            reloadOnUpdate
            static
            flickityRef={(e) => (mobileCarouselRef = e)}
          >
            <img
              src={props.phoneImages[0]}
              alt=""
              className="phoneProjectImages"
            />
            <img
              src={props.phoneImages[1]}
              alt=""
              className="phoneProjectImages"
            />
            <img
              src={props.phoneImages[2]}
              alt=""
              className="phoneProjectImages"
            />
          </Flickity>
        </div>
      </div>
    </div>
  );
}

export default ProjectCarousels;
