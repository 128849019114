import React from "react";
import "./About.css";

function About() {
  return (
    <div className="about" id="about">
      <div id="aboutTitle" className="text-center">
        <h2># A b o u t</h2>

        <div className="personalInfo">
          <p>
            <span className="highlights">
              I love a good technical challenge!
            </span>{" "}
            My passion for technology and logic made me pick this career. Even
            under pressure, I find myself smiling and coming back for more :)
          </p>

          <p>
            Expect a <span className="highlights">go-getter</span> professional
            that's <span className="highlights">detail-oriented</span> and{" "}
            <span className="highlights">restless</span> when it comes to
            achieving good results.
          </p>

          <p>
            I value connecting with my co-workers and{" "}
            <span className="highlights">working as a team</span>. For that, I
            bring an <span className="highlights">upbeat personality </span>
            and strive to develop{" "}
            <span className="highlights">empathic relationships</span> in the
            workplace.
          </p>

          <p>
            In my free time, you'll find me playing guitar, watching movies, and
            going for long walks. Oh, and trying out new sushi places :)
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
