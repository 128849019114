import React, { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import "./Header.css";

function Header() {
  useEffect(() => {
    if (window.innerWidth > document.documentElement.clientWidth) {
      document.getElementById("headerOptions").style.paddingRight = "40px";
    } else {
      document.getElementById("headerOptions").style.paddingRight = "15px";
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth > document.documentElement.clientWidth) {
        document.getElementById("headerOptions").style.paddingRight = "40px";
      } else {
        document.getElementById("headerOptions").style.paddingRight = "15px";
      }
    });
  }, []);

  const openFullScreenMenu = () => {
    document
      .getElementById("fullScreenMenu")
      .classList.add("showFullScreenMenu");
  };

  const closeFullScreenMenu = () => {
    document
      .getElementById("fullScreenMenu")
      .classList.remove("showFullScreenMenu");
  };

  const offsetScroll = (e) => {
    const position = e.getBoundingClientRect().top + window.pageYOffset;
    const headerHeight = document.getElementById("myHeader").offsetHeight;
    window.scrollTo({ top: position - headerHeight, behavior: "smooth" });
  };

  return (
    <div className="header" id="myHeader">
      <div
        id="headerOptions"
        className="d-flex justify-content-between align-items-center"
      >
        <HashLink smooth to="#home">
          <h1 className="d-inline-block">b r u n o d e v . c o m</h1>
        </HashLink>
        <div className="headerOptions d-none d-lg-inline-block">
          <HashLink
            to="#projects"
            scroll={(e) => offsetScroll(e)}
            style={{ cursor: "inherit" }}
          >
            <p className="d-inline-block" style={{ cursor: "pointer" }}>
              <span className="afterParagraph">Projects</span>
            </p>
          </HashLink>
          <HashLink
            smooth
            to="#skills"
            scroll={(e) => offsetScroll(e)}
            style={{ cursor: "inherit" }}
          >
            <p className="d-inline-block" style={{ cursor: "pointer" }}>
              <span className="afterParagraph">Skills</span>
            </p>
          </HashLink>
          <HashLink
            smooth
            to="#about"
            scroll={(e) => offsetScroll(e)}
            style={{ cursor: "inherit" }}
          >
            <p className="d-inline-block" style={{ cursor: "pointer" }}>
              <span className="afterParagraph">About</span>
            </p>
          </HashLink>

          <HashLink
            to="/Documents/Bruno_Cabral_Resume_June_2021.pdf"
            target="_blank"
            style={{ cursor: "inherit" }}
          >
            <p className="d-inline-block" style={{ cursor: "pointer" }}>
              <span className="afterParagraph">Resume</span>
            </p>
          </HashLink>

          <HashLink
            smooth
            to="#contact"
            scroll={(e) => offsetScroll(e)}
            style={{ cursor: "inherit" }}
          >
            <p className="d-inline-block" style={{ cursor: "pointer" }}>
              <span className="afterParagraph">Contact</span>
            </p>
          </HashLink>
        </div>
        <p className="d-inline-block d-lg-none" onClick={openFullScreenMenu}>
          <MenuIcon style={{ cursor: "pointer" }} />
        </p>
      </div>
      <div id="fullScreenMenu">
        <div id="fullScreenMenuOptions">
          <HashLink smooth to="#home" style={{ textDecoration: "none" }}>
            <p onClick={closeFullScreenMenu}>Home</p>
          </HashLink>
          <HashLink
            to="#projects"
            scroll={(e) => offsetScroll(e)}
            style={{ textDecoration: "none" }}
          >
            <p onClick={closeFullScreenMenu}>Projects</p>
          </HashLink>
          <HashLink
            to="#skills"
            scroll={(e) => offsetScroll(e)}
            style={{ textDecoration: "none" }}
          >
            <p onClick={closeFullScreenMenu}>Skills</p>
          </HashLink>
          <HashLink
            to="#about"
            scroll={(e) => offsetScroll(e)}
            style={{ textDecoration: "none" }}
          >
            <p onClick={closeFullScreenMenu}>About</p>
          </HashLink>

          <HashLink
            to="/Documents/Bruno_Cabral_Resume_June_2021.pdf"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <p onClick={closeFullScreenMenu}>Resume</p>
          </HashLink>

          <HashLink
            to="#contact"
            scroll={(e) => offsetScroll(e)}
            style={{ textDecoration: "none" }}
          >
            <p onClick={closeFullScreenMenu}>Contact</p>
          </HashLink>
        </div>
        <CloseIcon id="closeIcon" onClick={closeFullScreenMenu} />
      </div>
    </div>
  );
}

export default Header;
