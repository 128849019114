import React from "react";
import "./Skills.css";

function Skills() {
  return (
    <div className="skills" id="skills">
      <div id="skillsTitle" className="text-center">
        <h2># S k i l l s</h2>
      </div>
      <div className="skillList">
        <p>HTML</p>
        <p>CSS + Bootstrap</p>
        <p>JavaScript</p>
        <p>React + Hooks</p>
        <p>Django + Rest Framework APIs</p>
        <p>NodeJs + Express</p>
        <p>MongoDB via Mongoose</p>
        <p>Google Firebase + Cloud Firestore</p>
        <p>Oracle SQL Developer</p>
        <p>MySQL Workbench</p>
        <p>Java</p>
        <p>C++</p>
        <p>C</p>
      </div>
    </div>
  );
}

export default Skills;
